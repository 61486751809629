.neoatlas_projects.progress {
  height: 20px !important;
}

.neoatlas_projects.progress .inner {
  padding-top: 11px;
  padding-left: 11px;
  font-size: 14px;
  color: #fff;
}

.neoatlas_projects.progress.green.outer {
  background-color: var(--bg-green);
}

.neoatlas_projects.progress.green .inner {
  background-color: var(--green);
}

.neoatlas_projects.progress.gray.outer {
  background-color: var(--gray);
}

.neoatlas_projects.progress.gray .inner {
  background-color: var(--gray-dark);
}

.neoatlas_projects.progress.red .inner {
  background-color: var(--red);
}

body[data-route="Workspaces/NeoAtlas Projects"] .ce-header {
  padding-bottom: 5px;
  border-radius: 0;
  font-size: 17px;
  color: var(--heading-color);
}

[data-theme=dark] body[data-route="Workspaces/NeoAtlas Projects"] .ce-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275);
}

[data-theme=light] body[data-route="Workspaces/NeoAtlas Projects"] .ce-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1490196078);
}

.kanban-sidebar button {
  width: 100%;
}

.kanban-sidebar li {
  list-style-type: none;
}